(function($) {
    $.extend(true, window, {
        "Slick": {
            "ColumnGroup": ColumnGroup
        }
    });

    function ColumnGroup() {
        var grid, $container, $groupHeaderColumns, self = this,
            isColumnGroupEnabled = false;
        var handler = new Slick.EventHandler();

        function init(_grid) {
            grid = _grid;
            $container = $(grid.getContainerNode());
            handler.subscribe(grid.onColumnsResized, onColumnsResized);
            handler.subscribe(grid.onColumnsReordered, onColumnsReordered);
        }

        function enableColumnGrouping(frozenColumn) {
            if(isColumnGroupEnabled) {
                return;
            }
            isColumnGroupEnabled = true;
            setGroupedColumns(frozenColumn);
        }

        function removeColumnGrouping() {
            if(!isColumnGroupEnabled) {
                return;
            }
            isColumnGroupEnabled = false;
            $groupHeaderColumns.remove();
            grid.resizeCanvas();
        }

        function setGroupedColumns(frozenColumn) {
            const hasFrozenColumn = frozenColumn !== undefined;
            const headerColumns = $container.find('.slick-header-columns-left');
            const rightHeaderColumns = $container.find('.slick-header-columns-right');
            $groupHeaderColumns = $('<div class="slick-group-header-columns ui-state-default"> </div>');
            $groupHeaderColumns.css({
                width: hasFrozenColumn ? (headerColumns.width() + rightHeaderColumns.width()) + "px" :
                    headerColumns.width() + "px",
                left: headerColumns.position().left + "px"
            });
            var columns = grid.getColumns();
            var columnGroups = getGroupedColumns(columns);
            setGroupIndex(columns);
            setColumnIndex(columns);
            $groupHeaderColumns.append(getGroupedColumnsTemplate(columnGroups, frozenColumn));
            $container.find('.slick-header').prepend($groupHeaderColumns);
            if (hasFrozenColumn) {
                const firstFrozenGroup = $container.find('.frozen-columns-header')[0]
                const secoundFrozenGroup = $container.find('.frozen-columns-header')[1]
                if (firstFrozenGroup && firstFrozenGroup?.nextSibling?.children?.length) {
                    firstFrozenGroup.children[1]?.offsetParent?.remove();
                }
                if (secoundFrozenGroup && secoundFrozenGroup?.children?.length) {
                    secoundFrozenGroup.children[0]?.offsetParent?.remove();
                }
            }
            setupGroupColumnReorder();
            columns.sort(groupCompare);
            grid.setColumns(columns);
            grid.resizeCanvas();
        }

        function getGroupedColumnsTemplate(columnGroups, frozenColumn) {
            const hasFrozenColumn = frozenColumn !== undefined;
            let slickColumns = "", borderWidth = 0;
            let frozenColumns = [];
            let columns = [];
            let columnIndex = 0;
            columnGroups.forEach((group) => {
                const name = Object.keys(group)[0];
                group[name].forEach((col) => {
                    if (hasFrozenColumn && (columnIndex <= frozenColumn)) {
                        frozenColumns.push({ group: name, column: col });
                    } else {
                        columns.push({ group: name, column: col });
                    }
                    columnIndex++;
                })
            })
            if (frozenColumns?.length) {
                frozenWidth = 0;
                frozenColumns.forEach((c) => {
                    frozenWidth += c.column.width;
                });
                slickColumns += `<div id="${'frozen-columns-header'}" class="ui-state-default slick-header-column frozen-columns-header"
                data-group-name="${''}" style="width:${frozenWidth}px;background-color:#f0f0f0">
                <div class="slick-column-name">${''}</div></div>`;
                borderWidth = 1;
            }
            groupedColumns = { };
            columns.forEach((c) => {
                if (groupedColumns[c.group] === undefined) {
                    groupedColumns[c.group] = { group: c.group, column: [c.column] };
                } else {
                    groupedColumns[c.group].column.push(c.column);
                }
            });
            Object.values(groupedColumns).forEach((group) => {
                groupName = group.group || '-';
                columns = group.column || [];
                let width = 0;
                columns.forEach((col, index) => {
                    width += col.width;
                });
                var idName = groupName.replace(/\s*/g, '').replace(/\&/g, '').replaceAll('/', '');
                slickColumns += `<div id="${idName}" class="ui-state-default slick-header-column"
                data-group-name="${groupName}" style="width:${width}px;background-color:#f0f0f0">
                <div class="slick-column-name">${groupName === '-' ? '' : groupName}</div></div>`;
                borderWidth = 1;
            });
            return slickColumns;
        }

        function setColumnIndex(columns) {
            columns.forEach(function(column, index) {
                column._index = index;
            });
        }

        function setGroupIndex(columns) {
            var groupNames = Object.keys(getGroupedColumns(columns));
            columns.forEach(function(column) {
                column._groupIndex = groupNames.indexOf(column.groupName);
                column._groupIndex = column._groupIndex === -1 ? groupNames.length : column._groupIndex;
            });
        }

        function setupGroupColumnReorder() {
            $groupHeaderColumns.sortable({
                containment: "parent",
                distance: 3,
                axis: "x",
                cursor: "default",
                tolerance: "intersection",
                helper: "clone",
                update: onColumnsReordered
            });
        }

        function onColumnsResized() {
            var columns = grid.getColumns(), borderWidth=0;
            if (!isColumnGroupEnabled) {
                self.onColumnsResized.notify(columns);
                return;
            }
            frozenColumn = grid.getOptions().frozenColumn || -1;
            let columnIndex = 0;
            let frozenHeaderWidth = 0;
            $.each(getGroupedColumns(columns), function(name, group) {
                let width = 0;
                group[Object.keys(group)[0]].forEach((column) => {
                    if (columnIndex <= frozenColumn) {
                        frozenHeaderWidth += column.width;
                    } else {
                        width += column.width;
                    }
                    columnIndex++;
                }, 0);
                var idName = Object.keys(group)[0].replace(/\s*/g, '').replace(/\&/g, '').replaceAll('/', '');
                $(`#${$container[0].id} #${idName}`).attr("style", `width:${width}px;background-color:#f0f0f0`);
            });
            $(`#${$container[0].id} #frozen-columns-header`).attr("style", `width:${frozenHeaderWidth}px;background-color:#f0f0f0`);
            self.onColumnsResized.notify(columns);
        }


        function onColumnsReordered() {
            var columns = grid.getColumns();
            setColumnIndex(columns);

            if (!isColumnGroupEnabled) {
                self.onColumnsReordered.notify(columns);
                return;
            }

            var $columns = $(".slick-group-header-columns .slick-header-column");
            var columnGroups = getGroupedColumns(columns);
            $columns.each(function(index, column) {
                var groupedColumns = columnGroups[$(column).data("group-name")];
                if (groupedColumns?.length) {
                    groupedColumns.forEach(function(groupedColumn) {
                        groupedColumn._groupIndex = index;
                    });
                }
            });
            columns.sort(groupCompare);
            grid.setColumns(columns);
            setColumnIndex(columns);
            self.onColumnsReordered.notify(columns);
        }

        function groupCompare(c1, c2) {
            return (c1._groupIndex - c2._groupIndex) || (c1._index - c2._index);
        }

        function getGroupedColumns(columns) {
            var groupedColumns = {};
            var originalGroupsOrder = [];

            columns.forEach(function(column) {
                var groupName = column.groupName || "-";
                groupedColumns[groupName] = groupedColumns[groupName] || [];
                groupedColumns[groupName].push(column);

                //Getting the original order
                if (originalGroupsOrder.indexOf(groupName) === -1){
                    originalGroupsOrder.push(groupName);
                }
            });

            var sorted = sortAsOriginal(originalGroupsOrder, groupedColumns);
            return sorted;
        }

        function sortAsOriginal(order, groupedColumns){
            var newGroupedColumns = new Array(order.length);

            for(i = 0; i < order.length; i++) {
                var key = order[i];

                newGroupedColumns[i] = [];
                $.each(groupedColumns, function(name , group) {
                    if(key === name){
                        const obj = {};
                        obj[key] = group;
                        newGroupedColumns[i] = obj;
                    }
                });
            }
            return newGroupedColumns;
        }

        function destroy() {
            handler.unsubscribeAll();
        }

        this.onColumnsReordered = new Slick.Event();
        this.onColumnsResized = new Slick.Event();

        return {
            init: init,
            destroy: destroy,
            onColumnsReordered: this.onColumnsReordered,
            onColumnsResized: this.onColumnsResized,
            enableColumnGrouping: enableColumnGrouping,
            removeColumnGrouping: removeColumnGrouping
        };
    }

}(jQuery));
